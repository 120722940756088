import ptBr from "../../config/texts/pt-br";
import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table
} from "../../components/index";
import Styles from "./styles";

import { masks, SessionStorage } from "../../utils";
import { hooks } from "../../utils";

import { fonts, SVG } from "../../config";

import Visibility from "@material-ui/icons/Visibility";
import { Check, Close, ExpandMore, Mail } from "@material-ui/icons";
import { customModal } from "../../components/modals/utils";

import ModalView from "./components/ModalView";
import { api } from "../../services";
import React, { useContext } from "react";
import { StorageContext } from "../../contexts/StorageContext";
import ModalAprove from "../registryReservation/components/ModalAprove";
import { BsPersonFillSlash } from "react-icons/bs";

const PAGE_SIZE = 8;

function Reservation() {
  const texts = ptBr.login;
  const [page, setPage] = React.useState<number>(0);
  const [pageAct, setPageAct] = React.useState<number>(0);
  const { loading, call } = hooks.useRequest();
  const [reservation, setReservation] = React.useState<any>(null);
  const [activeReservation, setActiveReservation] = React.useState<any>(null);
  const [isMounted, setMount] = React.useState<boolean>(false);
  const { getData, setData, userData, setUserData } =
    useContext<any>(StorageContext);

  const Mount = React.useCallback(async () => {
    if (userData && userData.newReservations > 0) {
      call(null, api.postConfirmNews(), async (response) => {
        if (response.ok) {
          setUserData({ ...userData, newReservations: 0 });
          SessionStorage.setItem("userData", {
            ...userData,
            newReservations: 0
          });
        }
      });
    }

    await call(
      null,
      api.getReservation({
        page: 0,
        size: PAGE_SIZE
      }),
      async (response) => {
        if (response.ok) {
          setReservation(response?.data);
          setPage(0);
        }
      }
    );
    await call(
      null,
      api.getReservation({
        page: 0,
        size: PAGE_SIZE,
        minStartDate: masks.dateToSubmit(new Date()),
        minEndDate: masks.dateToSubmit(new Date())
      }),
      async (response) => {
        if (response.ok) {
          setActiveReservation(response?.data);
          setPageAct(0);
        }
      }
    );
  }, [userData]);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted, getData]);

  const Submit = async (page?: number) => {
    let filters = {
      page: page ? page : 0,
      size: PAGE_SIZE
    };

    call(null, api.getReservation(filters), async (response) => {
      if (response.ok) {
        setReservation(response?.data);
        setPage(page ? page : 0);
      }
    });
  };

  const openModalView = (item) => {
    call(null, api.getReservationData(item.id), async (response) => {
      if (response.ok) {
        customModal.setInfos(
          "Visualizar Ficha de Hóspedes",
          [],
          {
            onClick: () => {
              customModal.close();
            },
            label: "Voltar"
          },
          null,
          <ModalView
            item={response.data}
            onSubmit={(value) => console.log(value)}
          />,
          false,
          true,
          <SVG.File />
        );
      }
    });
  };

  const getStatus = (status: string) => {
    if (status === "FP") {
      return "Foto Pendente";
    } else if (status === "PA") {
      return "Aguardando Liberação";
    } else if (status === "AL") {
      return "Acesso Liberado";
    } else if (status === "AF") {
      return "Acesso Finalizado";
    } else if (status === "AR") {
      return "Acesso Recusado";
    } else if (status === "AC") {
      return "Acesso Cancelado";
    }
  };

  const openAproveModal = (item) => {
    customModal.setInfos(
      "Aprovar Reserva",
      [],
      null,
      null,
      <ModalAprove page={pageAct} item={item} reload={(value) => Mount()} />,
      false,
      true,
      <Check />
    );
  };

  const deniedAccess = (id: string) => {
    call(null, api.patchDeniedAccess({ reservationId: id }), (response) => {
      if (response.ok) {
        Mount();
        customModal.setInfos(
          "Recusar Reserva",
          ["Reserva recusada com sucesso!"],
          {
            label: "Ok, Entendi",
            onClick: () => {
              customModal.close();
            }
          },
          null,
          null,
          false,
          true,
          <Close />
        );
      }
    });
  };
  const revokeAccess = (id: string) => {
    call(null, api.patchReservationRevoke(id), (response) => {
      if (response.ok) {
        Mount();
      }
    });
  };
  const deniedPhoto = (id: string) => {
    call(null, api.patchDeniedPhoto({ reservationId: id }), (response) => {
      if (response.ok) {
        Mount();
        customModal.setInfos(
          "Recusar Foto",
          ["Foto recusada com sucesso!"],
          {
            label: "Ok, Entendi",
            onClick: () => {
              customModal.close();
            }
          },
          null,
          null,
          false,
          true,
          <Close />
        );
      }
    });
  };
  const resendEmail = (data: any) => {
    call(null, api.resendEmailAccess(data), (response) => {
      if (response.ok) {
        Mount();
        customModal.setInfos(
          "Reenviar E-mail",
          ["E-mail enviado com sucesso!"],
          {
            label: "Ok, Entendi",
            onClick: () => {
              customModal.close();
            }
          },
          null,
          null,
          false,
          true,
          <Mail />
        );
      }
    });
  };

  const getButtons = (status: string, item: any): any[] => {
    if (status === "PA") {
      return [
        {
          label: "Aprovar Reserva",
          icon: Check,
          onClick: () => {
            openAproveModal(item);
          }
        },
        {
          label: "Recusar Foto",
          icon: Close,
          onClick: () => {
            deniedPhoto(item.id);
          }
        },
        {
          label: "Reenviar E-mail",
          icon: Mail,
          onClick: () => {
            resendEmail({ reservationId: item.id });
          }
        },
        {
          label: "Visualizar Ficha de Hóspedes",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          }
        },
        {
          label: "Cancelar Reserva",
          icon: BsPersonFillSlash,
          onClick: () => {
            customModal.setInfos(
              "Cancelar Reserva",
              [
                "Ao cancelar o reserva deste imóvel, o usuário não seguir com o cadastro da ficha de hospedes. Continuar com o cancelamento da reserva?"
              ],
              {
                onClick: () => {
                  deniedAccess(item.id);
                  customModal.close();
                },
                label: "Sim, Cancelar"
              },
              {
                onClick: () => {
                  customModal.close();
                },
                label: "Voltar"
              },
              null,
              null,
              null,
              <BsPersonFillSlash />
            );
          }
        }
      ];
    } else if (status === "AL") {
      return [
        {
          label: "Visualizar Ficha de Hóspedes",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          }
        },

        {
          label: "Revogar Acesso",
          icon: BsPersonFillSlash,
          onClick: () => {
            customModal.setInfos(
              "Revogar Acesso",
              [
                "Ao revogar o acesso deste imóvel, o usuário vinculado com o mesmo terá sua chave atual desativada, porém sua compra referente a este imóvel continuará ativa. Continuar e revogar acesso?"
              ],
              {
                onClick: () => {
                  revokeAccess(item.id);
                  customModal.close();
                },
                label: "Sim, Revogar"
              },
              {
                onClick: () => {
                  customModal.close();
                },
                label: "Voltar"
              },
              null,
              null,
              null,
              <BsPersonFillSlash />
            );
          }
        }
      ];
    } else if (status === "AF") {
      return [
        {
          label: "Visualizar Ficha de Hóspedes",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          }
        }
      ];
    } else if (status === "AR") {
      return [
        {
          label: "Visualizar Ficha de Hóspedes",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          }
        }
      ];
    } else if (status === "AC") {
      return [
        {
          label: "Visualizar Ficha de Hóspedes",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          }
        }
      ];
    } else if (status === "FP") {
      return [
        {
          label: "Visualizar Ficha de Hóspedes",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          }
        },
        {
          label: "Reenviar E-mail",
          icon: Mail,
          onClick: () => {
            resendEmail({ reservationId: item.id });
          }
        },
        {
          label: "Cancelar Reserva",
          icon: BsPersonFillSlash,
          onClick: () => {
            customModal.setInfos(
              "Cancelar Reserva",
              [
                "Ao cancelar o reserva deste imóvel, o usuário não seguir com o cadastro da ficha de hospedes. Continuar com o cancelamento da reserva?"
              ],
              {
                onClick: () => {
                  deniedAccess(item.id);
                  customModal.close();
                },
                label: "Sim, Cancelar"
              },
              {
                onClick: () => {
                  customModal.close();
                },
                label: "Voltar"
              },
              null,
              null,
              null,
              <BsPersonFillSlash />
            );
          }
        }
      ];
    } else {
      return [
        {
          label: "Visualizar Ficha de Hóspedes",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          }
        },
        {
          label: "Cancelar Reserva",
          icon: BsPersonFillSlash,
          onClick: () => {
            customModal.setInfos(
              "Cancelar Reserva",
              [
                "Ao cancelar o reserva deste imóvel, o usuário não seguir com o cadastro da ficha de hospedes. Continuar com o cancelamento da reserva?"
              ],
              {
                onClick: () => {
                  deniedAccess(item.id);
                  customModal.close();
                },
                label: "Sim, Cancelar"
              },
              {
                onClick: () => {
                  customModal.close();
                },
                label: "Voltar"
              },
              null,
              null,
              null,
              <BsPersonFillSlash />
            );
          }
        }
      ];
    }
  };

  const setPageActive = (p: any) => {
    call(
      null,
      api.getReservation({
        page: p,
        size: PAGE_SIZE,
        minStartDate: masks.dateToSubmit(new Date()),
        minEndDate: masks.dateToSubmit(new Date())
      }),
      async (response) => {
        if (response.ok) {
          setPageAct(p);
          setActiveReservation(response?.data);
        }
      }
    );
  };

  return (
    <>
      <Styles.ContainerSecondary>
        <Styles.RegitryContainer>
          <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
            Solicitações Ativas
          </CustomText>
        </Styles.RegitryContainer>
        <ContentFeedback
          secondaryColor
          data={activeReservation?.content}
          loading={loading}
        >
          <Table
            alternative
            lastAlign={false}
            data={activeReservation?.content}
            renderItemColumns={(item) => [
              item?.place?.name,
              masks.formatDate(item?.createdAt),
              <CustomText textColor="#fff" style={{ gap: "3px" }}>
                <b>{masks.formatDate(item?.startDate)} </b> a
                <b>{masks.formatDate(item?.endDate)}</b>
              </CustomText>,
              <CustomText textColor="#fff" style={{ gap: "3px" }}>
                {masks.extractHour(item?.startDate)} 
              </CustomText>,
              <CustomText textColor="#fff" style={{ gap: "3px" }}>
                {masks.extractHour(item?.endDate)}
              </CustomText>,
              item?.numGuests,
              <b>{getStatus(item?.status)}</b>,
              <ButtonMenu
                alternative
                endIcon={<ExpandMore />}
                buttons={getButtons(item.status, item)}
              >
                Opções
              </ButtonMenu>
            ]}
            headers={{
              table: [
                "IMÓVEL",
                "DATA SOLICITAÇÃO",
                "ESTADIA",
                "CHECK-IN",
                "CHECK-OUT",
                "Nº ACOMPANHANTES",
                "STATUS",
                "AÇÃO"
              ],
              keys: []
            }}
          />
          <Pagination
            alternative
            style={{ paddingInline: "20px", paddingTop: 0 }}
            page={pageAct + 1}
            totalPages={activeReservation?.totalPages}
            setPage={(number) => {
              setPageActive(number - 1);
            }}
          />
        </ContentFeedback>
      </Styles.ContainerSecondary>
      <Styles.Container>
        <CustomText
          style={{ padding: "16px 20px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Histórico de Reservas
        </CustomText>
        <ContentFeedback data={reservation?.content} loading={loading}>
          <Table
            lastAlign={false}
            data={reservation?.content}
            renderItemColumns={(item) => [
              item?.place?.name,
              masks.formatDate(item?.createdAt),
              <CustomText textColor="#4D585A" style={{ gap: "3px" }}>
                <b>{masks.formatDate(item?.startDate)} </b> a
                <b>{masks.formatDate(item?.endDate)}</b>
              </CustomText>,
              <CustomText textColor="#4D585A" style={{ gap: "3px" }}>
                {masks.extractHour(item?.startDate)} 
              </CustomText>,
              <CustomText textColor="#4D585A" style={{ gap: "3px" }}>
                {masks.extractHour(item?.endDate)}
              </CustomText>,
              item?.numGuests,
              <b>{getStatus(item?.status)}</b>,
              <Styles.ViewButton
                fullWidth={false}
                onClick={() => openModalView(item)}
                endIcon={<Visibility />}
              >
                Visualizar
              </Styles.ViewButton>
            ]}
            headers={{
              table: [
                "IMÓVEL",
                "DATA SOLICITAÇÃO",
                "ESTADIA",
                "CHECK-IN",
                "CHECK-OUT",
                "Nº ACOMPANHANTES",
                "STATUS",
                "FICHA DE HÓSPEDES"
              ],
              keys: []
            }}
          />
          <Pagination
            style={{ padding: "16px 20px", paddingTop: 0 }}
            page={page + 1}
            totalPages={reservation?.totalPages}
            setPage={(number) => {
              Submit(number - 1);
            }}
          />
        </ContentFeedback>
      </Styles.Container>
    </>
  );
}

export default Reservation;
