import React, { useState } from "react";
import { CustomText, Input, InputAutocomplete, InputDate, InputTags } from "../../../components/index";
import { Grid } from "@material-ui/core";
import { hooks, masks, validations } from "../../../utils";
import { fonts } from "../../../config";
import Styles from "../styles/Styles";
import { ErrorMessageType, FormFull, FormFullData } from "form-full";
import { customModal } from "../../../components/modals/utils";
import { LabelView } from "../../../components/inputs/Input";
import { MyLabel } from "../../registryReservation/components/ModalRegistry";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { api } from "../../../services";
import ResponseError from "../../../services/helpers/ResponseError";
import errorStatusMessage from "../../../services/helpers/errorStatusMessage";
import alerts from "../../../utils/alerts";

function ModalRegistry({ data, placeList, onSubmit }: { data?: any; placeList: { value: string; label: string }[]; onSubmit?: any }) {
  const phone = React.useRef<any>(data?.phone)
  const [formRef, setFormRef] = React.useState<any>(null);
  const [minDate, setMinDate] = React.useState<any>(new Date());
  const [unError, setUnError] = React.useState<any>(false);
  const [timeError, setTimeError] = React.useState<any>(false);
  const [hourError, setHourError] = React.useState<any>(false);
  const [warning, setWarning] = React.useState<any>(false);
  function setPhone(value){
    phone.current = value;
  }
  const { loading, call } = hooks.useRequest();
  

  const validadeTime = () => {
    const localId = formRef?.getValue("property", false);
    const startDate = formRef?.getValue("startDate", false);
    const endDate = formRef?.getValue("endDate", false);
    if (
      isValidDate(startDate) &&
      isValidDate(endDate) &&
      localId &&
      endDate &&
      startDate
    ) {
      const startSub = masks.dateToSubmit(startDate) + " 00:00";
      const endSub = masks.dateToSubmit(endDate) + " 00:00";

      let subData = {
        placeId: localId,
        startDate: startSub,
        endDate: endSub,
      };

      if (data) {
        subData["reservationId"] = data.id;
      }

      call(
        null,
        api.validadeTime(subData),
        (response) => {
          if (response?.ok) {
            if (response?.data?.value === false) {
              setHourError(null);
            } else {
              setHourError(null);
              setWarning("Esse horário esta em uso");
            }
          }
        },
        (response) => {
          const errorResponse = new ResponseError(response);
          if (errorResponse.message) {
            setHourError(errorResponse.message);
          } else {
            const errorMsg = errorStatusMessage(response);

            alerts.alertError(errorMsg);
            setHourError(
              "*Não foi possível validar o horário. Tente Novamente"
            );
          }
        }
      );
    } else {
      setHourError("Selecione um Local e Período");
    }
  };

  function checkStartData():boolean {//Devolve se a data selecionada é igual a inicial ou menor que a atual.
    const startDate = formRef?.getValue("startDate", false);
    if(data){
      if(!(data?.startDate<startDate)&&
         !(data?.startDate>startDate)){
        return true;
      }
    }
    return startDate<new Date();
  }

  const customDateValidStartData = (mydata, maxDate, minDate): ErrorMessageType => {
    if(mydata+""===data?.startDate+""){
      setTimeError(false);
      return;
    }

    const error = validations.inputDate(mydata, maxDate, minDate);

    if (Boolean(error)) {
      setTimeError("*Período invalido");
    } else {
      setTimeError(false);
    }
    return error;
  };

  const addOneDay = (date) => {
    const result = new Date(date);
    result.setDate(result.getDate() + 1);
    return result;
  };

  function isValidDate(date: any): boolean {
    const parsedDate = new Date(date);
    return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
  }

  const customDateValid = (data, maxDate, minDate): ErrorMessageType => {
    const error = validations.inputDate(data, maxDate, minDate);

    if (Boolean(error)) {
      setTimeError("*Período invalido");
    } else {
      setTimeError(false);
    }
    return error;
  };

  const editSP = async (
    values: FormFullData<{
      name:string,
      cnpj:string,
      responsibleName:string,
      phone:string,
      email:string,
    }>
  ) => {
    let submitData = {
      email:values.email,
      name:values.name,
      responsibleName:values.responsibleName,
      cnpj:values.cnpj,
      phone:values.phone
    };
    call(null, api.putServiceProvider(submitData), (response) => {
      if (response.ok) {        
        customModal.close();
      }
    });
  };

  const createSP = async (
    values: FormFullData<
    {
      name:string,
      cnpj:string,
      responsibleName:string,
      phone:string,
      email:string,
    }>
  ) => {
    let submitData = {
      email:values.email,
      name:values.name,
      responsibleName:values.responsibleName,
      cnpj:values.cnpj,
      phone:values.phone
    };
    call(null, api.postServiceProvider(submitData), (response) => {
      if (response.ok) {        
        customModal.close();
      }
    });
  };

  function handleSubmit(dados) {
    dados.phone = phone.current+"";
    if (data) {
      editSP(dados);
    } else {
      createSP(dados);
    }
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull
          formRef={setFormRef} onSubmit={handleSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DO INQUÍLINO
          </CustomText>
          <Grid alignItems="flex-end" container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                disableError
                name="fullName"
                label="NOME COMPLETO"
                defaultValue={data?.fullName || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <LabelView>
                <MyLabel
                  white
                  className={"form-input-label-" + "telefone"?.replace(/\s/g, "")}
                >
                  {"Telefone*"}
                </MyLabel>
              </LabelView>
              <div style={{backgroundColor:"#E9E9E9", height:"48px", alignContent:"center", border:"0px", borderRadius:"5px", padding:"5px"}}>
              <PhoneInputWithCountrySelect
                placeholder="+55 99 99999-9999"
                numberInputProps={{className:"focus:outline-none focus:ring-0",}}
                value={phone.current}
                onChange={setPhone}
              />
              </div>
              {/*
              <Input
                disableError
                name="phone"
                label="E-mail"
                defaultValue={data?.email || ""}
                validation={validations.isEmailValid}
                required={"*Campo Obrigatório"}
                white
              />*/}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                disableError
                name="email"
                label="E-mail"
                defaultValue={data?.email || ""}
                validation={validations.isEmailValid}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InputAutocomplete
                readOnly={data}
                defaultValue={data?.place?.id}
                disableError
                required={"*Campo Obrigatório"}
                white
                name="property"
                label="Imóvel"
                options={placeList ? placeList : []}
                onBlur={validadeTime}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Styles.Label $witherror={Boolean(timeError) ? true : undefined}>
                estadia*
              </Styles.Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  gap: "15px",
                  width: "100%",
                }}
              >
                <InputDate
                  onBlur={validadeTime}
                  disableError
                  required={"*Campo Obrigatório"}
                  onChange={(value) => {
                    setMinDate(value);
                    const end = formRef?.getValue("endDate");

                    if (end < value) {
                      formRef?.clearValue("endDate", false);
                    }
                  }}
                  name="startDate"
                  customValidation={customDateValidStartData}
                  minDate={new Date()}
                />{" "}
                <CustomText
                  fontFamily={fonts.medium}
                  fontSize={14 / 8}
                  textColor="#fff"
                  style={{ paddingBottom: "16px" }}
                >
                  até
                </CustomText>{" "}
                <InputDate
                  disableError
                  required={"*Campo Obrigatório"}
                  minDate={addOneDay(minDate)}
                  name="endDate"
                  customValidation={customDateValid}
                  onBlur={validadeTime}
                />
              </div>
            </Grid>
          </Grid>
            <CustomText
              style={{ whiteSpace: "pre-wrap" }}
              fontSize={1.75}
              textColor="#ff7733"
              
            >
              {unError && "*Campos Obrigatórios \n"}
              {timeError && timeError + "\n"}
              {hourError && "*" + hourError}
              {warning && "*" + warning}
            </CustomText>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "40px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => {
                customModal.close();
              }}
            >
              Cancelar
            </Styles.CancelButton>
            {/*
            <Styles.RegitryButton fullWidth={false} action="submit">
              Confirmar {data ? "Edição" : "Cadastro"}
            </Styles.RegitryButton>
            */}
            
            <Styles.RegitryButton
                fullWidth={false}
                loading={loading}
                disabled={loading}
                onClick={async () => {
                  const submitInfo = await formRef?.testErrorsAndReturnData();
                  const startError = !checkStartData();
                  const endError = await formRef?.testFieldError("endDate");

                  if (Boolean(startError) || Boolean(endError)) {
                    if (startError) {
                      setTimeError("Data inicial: " + startError);
                    } else {
                      setTimeError("Data final: " + endError);
                    }
                  } else {
                    setTimeError(false);
                  }

                  if (submitInfo?.hasError || hourError) {
                    setUnError(true);
                  } else {
                    setUnError(false);
                    formRef?.submit();
                  }
                }}
              >
                Confirmar Cadastro
              </Styles.RegitryButton>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
