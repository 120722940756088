import React, { useState } from "react";
import Styles from "./styles/Styles";
import { fonts, SVG } from "../../config";
import { Add, ExpandMore } from "@material-ui/icons";
import Filters from "./components/Filters";
import { masks } from "../../utils";
import { customModal } from "../../components/modals/utils";
import ModalRegistry from "./components/ModalRegistry";
import { HiCheck, HiMiniHandRaised, HiMiniNoSymbol, HiPencil } from "react-icons/hi2";
import { IoMdTrash } from "react-icons/io";
import {
    ButtonMenu,
    ContentFeedback,
    CustomText,
    Pagination,
    Table,
} from "../../components";



export default function SteakhouseRentalPage() {
    const [page, setPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = useState(0);
    const statuslist = {
        Aprovado: "Aprovado",
        Pendente: "Pendente",
        Reprovado: "Reprovado"
    }
    const MOCK_DATA = [
        {
            id: 1,
            personName: "Leandro Bittencourt",
            cpf: "04698703342",
            email: "lea.bittencourt@email.com.br",
            phone: "(48) 99846-0322",
            date: "05/07/2024",
            time: "08:00 - 14:00",
            status: statuslist.Aprovado,
        },
        {
            id: 2,
            personName: "Ethel Soares",
            cpf: "00345203973",
            email: "e-mail@exemplo.com.br",
            phone: "(48) 99846-0322",
            date: "06/07/2024",
            time: "15:00 - 19:00",
            status: statuslist.Pendente,
        },
        {
            id: 1,
            personName: "Leandro Bittencourt",
            cpf: "04698703342",
            email: "lea.bittencourt@email.com.br",
            phone: "(48) 99846-0322",
            date: "05/07/2024",
            time: "08:00 - 14:00",
            status: statuslist.Reprovado,
        },
        {
            id: 2,
            personName: "Ethel Soares",
            cpf: "00345203973",
            email: "e-mail@exemplo.com.br",
            phone: "(48) 99846-0322",
            date: "06/07/2024",
            time: "15:00 - 19:00",
            status: statuslist.Aprovado,
        },
        {
            id: 1,
            personName: "Leandro Bittencourt",
            cpf: "04698703342",
            email: "lea.bittencourt@email.com.br",
            phone: "(48) 99846-0322",
            date: "05/07/2024",
            time: "08:00 - 14:00",
            status: statuslist.Reprovado,
        },
    ];

    const openRegisterModal = () => {
        customModal.setInfos(
            "Novo Agendamento de Churrasqueira / Pub",
            [],
            null,
            null,
            <ModalRegistry />,
            false,
            true,
            <SVG.ClanedarHourIcon />
        );
    };

    const openAprovalModal = (data) => {
        customModal.setInfos(
            "Aprovar Agendamento",
            [
                "Tem certeza que deseja aprovar este agendamento da churrasqueira / pub?",
            ],
            {
                label: "Sim, Aprovar Agendamento",
                onClick: () => {
                    customModal.close();
                },
            },
            {
                label: "Voltar",
                onClick: () => {
                    customModal.close();
                },
            },
            null,
            null,
            null,
            <IoMdTrash />
        );
    };

    const openReprovalModal = (data) => {
        customModal.setInfos(
            "Reprovar Agendamento",
            [
                "Tem certeza que deseja reprovar este agendamento da churrasqueira / pub?",
            ],
            {
                label: "Sim, Reprovar Agendamento",
                onClick: () => {
                    customModal.close();
                },
            },
            {
                label: "Voltar",
                onClick: () => {
                    customModal.close();
                },
            },
            null,
            null,
            null,
            <IoMdTrash />
        );
    };

    const openPendingModal = (data) => {
        customModal.setInfos(
            "Deixar Pendente este Agendamento",
            [
                "Tem certeza que deseja deixar pendente este agendamento da churrasqueira / pub?",
            ],
            {
                label: "Sim, deixe Pendente este Agendamento",
                onClick: () => {
                    customModal.close();
                },
            },
            {
                label: "Voltar",
                onClick: () => {
                    customModal.close();
                },
            },
            null,
            null,
            null,
            <IoMdTrash />
        );
    };
    

    const openEditModal = (data) => {
        customModal.setInfos(
            "Editar Dados do Agendamento",
            [],
            null,
            null,
            <ModalRegistry data={data} onSubmit={data} />,
            false,
            true,
            <HiPencil />
        );
    };

    const RemoveModal = (id: string) => {
        customModal.setInfos(
            "Remover Agendamento",
            [
                "Tem certeza que deseja remover este agendamento da churrasqueira / pub?",
            ],
            {
                label: "Sim, Remover Agendamento",
                onClick: () => {
                    customModal.close();
                },
            },
            {
                label: "Voltar",
                onClick: () => {
                    customModal.close();
                },
            },
            null,
            null,
            null,
            <IoMdTrash />
        );
    };

    const getButtons = (item: any): any[] => {
        if(item.status===statuslist.Aprovado){
            return [
                {
                    label: "Reprovar",
                    icon: HiMiniNoSymbol,
                    onClick: () => openReprovalModal(item),
                },
                {
                    label: "Deixar pendente",
                    icon: HiMiniHandRaised,
                    onClick: () => openPendingModal(item),
                },
                {
                    label: "Editar Dados do Agendamento",
                    icon: HiPencil,
                    onClick: () => openEditModal(item),
                },
                {
                    label: <div style={{ color: "#FF0000" }}>{"Remover Agendamento"}</div>,
                    icon: (props) => <IoMdTrash {...props} style={{ color: "#FF0000" }} />,
                    onClick: () => RemoveModal(item.id),
                },
            ];

        }else if(item.status === statuslist.Reprovado){
            return [
                {
                    label: "Aprovar",
                    icon: HiCheck,
                    onClick: () => openAprovalModal(item),
                },
                {
                    label: "Deixar pendente",
                    icon: HiMiniHandRaised,
                    onClick: () => openPendingModal(item),
                },
                {
                    label: "Editar Dados do Agendamento",
                    icon: HiPencil,
                    onClick: () => openEditModal(item),
                },
                {
                    label: <div style={{ color: "#FF0000" }}>{"Remover Agendamento"}</div>,
                    icon: (props) => <IoMdTrash {...props} style={{ color: "#FF0000" }} />,
                    onClick: () => RemoveModal(item.id),
                },
            ];

        }else{
            return [
                {
                    label: "Aprovar",
                    icon: HiCheck,
                    onClick: () => openAprovalModal(item),
                },
                {
                    label: "Reprovar",
                    icon: HiMiniNoSymbol,
                    onClick: () => openReprovalModal(item),
                },
                {
                    label: "Editar Dados do Agendamento",
                    icon: HiPencil,
                    onClick: () => openEditModal(item),
                },
                {
                    label: <div style={{ color: "#FF0000" }}>{"Remover Agendamento"}</div>,
                    icon: (props) => <IoMdTrash {...props} style={{ color: "#FF0000" }} />,
                    onClick: () => RemoveModal(item.id),
                },
            ];
        }


    };

    return (
        <>
            <Styles.PageNameContainer>
                <Styles.PageNameContent>
                    <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
                        Agendar Locação da Churrasqueira / Pub
                    </CustomText>
                    <CustomText
                        style={{ paddingRight: "8px" }}
                        fontSize={1.75}
                        textColor="#FFFFFF"
                        fontFamily={fonts.regular}
                    >
                        Alguns dados básicos serão solicitados para o agendamento de uma
                        nova locação da churrasqueira / pub.
                    </CustomText>
                </Styles.PageNameContent>
                <Styles.RegitryButton
                    startIcon={<Add />}
                    fullWidth={false}
                    onClick={() => openRegisterModal()}
                >
                    Novo Agendamento
                </Styles.RegitryButton>
            </Styles.PageNameContainer>

            <Styles.ContainerFilter>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Filtrar Locações da Churrasqueira / Pub
                </CustomText>
                <Filters onSubmit={(data) => console.log(data)} loading={false} />
            </Styles.ContainerFilter>

            <Styles.Container>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Lista de Locações da Churrasqueira / Pub
                </CustomText>
                <ContentFeedback data={MOCK_DATA} loading={false}>
                    <Table
                        lastAlign={false}
                        data={MOCK_DATA}
                        renderItemColumns={(item) => [
                            item.personName,
                            masks.CPF(item.cpf),
                            item.email,
                            item.date,
                            item.time,
                            item.status,
                            null,
                            <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                                Opções
                            </ButtonMenu>,
                        ]}
                        headers={{
                            table: [
                                "NOME RESPONSÁVEL",
                                "CPF",
                                "E-MAIL",
                                "DATA",
                                "HORÁRIO",
                                "",
                                "",
                                "AÇÃO",
                            ],
                            keys: [],
                        }}
                    />
                    <Pagination
                        style={{ padding: "16px 20px", paddingTop: 0 }}
                        page={page}
                        totalPages={totalPages}
                        setPage={(number) => setPage(number)}
                    />
                </ContentFeedback>
            </Styles.Container>
        </>
    );
}
