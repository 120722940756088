import React, { useRef, useState } from "react";
import {
  CustomText,
  Input,
  InputRadios,
  InputTags,
} from "../../../components/index";
import { Grid } from "@material-ui/core";
import { hooks, masks, validations } from "../../../utils";
import { fonts } from "../../../config";
import Styles from "../styles";
import { FormFull, FormFullData } from "form-full";
import { customModal } from "../../../components/modals/utils";
import { OwnersProps } from "../RegisterOwners";
import { api } from "../../../services";

export interface Studio {
  label: string;
  value: string;
}

export interface DataRegisterProps {
  id: string;
  name: string;
  email: string;
  cpf: string;
  studios: Studio[];
}

export interface DataEditProps {
  id: string;
  name: string;
  studios: Studio[];
}

function ModalRegistry({
  data,
  view,
  onSubmit,
  placeList,
}: {
  data?: OwnersProps;
  view?: boolean;
  onSubmit?: () => void;
  placeList?: any[];
}) {
  const { call } = hooks.useRequest();
  const [formRef, setFormRef] = useState<any>(null);
  const loadingRef = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(loadingRef.current);

  const selectedStudios = data?.places || [];

  const filteredPlaceList = placeList?.filter((place) =>
    selectedStudios.some((studio) => studio.id === place.value)
  );
  const registerOwner = async (data: DataRegisterProps) => {
    const { cpf, email, name } = data;
    const studiosIds = data.studios.map((studio) => {
      return studio.value;
    });
    if(studiosIds[0]==="all"){
      studiosIds.shift();
    }
    if(studiosIds.length>-10){
      call(
        null,
        api.postOwners({
          cpf,
          email,
          name,
          studiosIds: studiosIds ?? [],
        }),
        async (response) => {
          if (response.ok) {
            onSubmit&&onSubmit();
            customModal.close();
          }
          loadingRef.current = false;
          setLoading(false);
        }
      );
    }else{
      loadingRef.current = false;
      setLoading(false);
    }
  };

  const editOwner = async (data: DataEditProps) => {
    const { id, name } = data;
    const studiosIds = data.studios.map((studio) => {
      return studio.value;
    });
    if(studiosIds[0]==="all"){
      studiosIds.shift();
    }
    if(studiosIds.length>0){
      call(
        null,
        api.putOwners({
          id,
          name,
          studiosIds: studiosIds ?? [],
        }),
        async (response) => {
          if (response.ok) {
            onSubmit&&onSubmit();
            customModal.close();
          }
          loadingRef.current = false;
          setLoading(false);
        }
      );
    }else{
      loadingRef.current = false;
      setLoading(false);
    }
  };

  const handleSubmit = (
    newOwner: FormFullData<{
      name: string;
      email: string;
      cpf: string;
      studios: string[];
    }>
  ) => {
    const formdata = {
      id: data ? data.id : "",
      cpf: newOwner.cpf,
      email: newOwner.email,
      name: newOwner.name,
      studios: newOwner.studios,
    };
    if (onSubmit){
      if(data){
        editOwner(formdata);
      }else{
        registerOwner(formdata);
      }
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull formRef={setFormRef} onSubmit={handleSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DO PROPRIETÁRIO
          </CustomText>
          <Grid alignItems="flex-end" container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                readOnly={view}
                required={"*Campo Obrigatório"}
                name="name"
                defaultValue={data?.name || ""}
                validation={validations.isValidFullname}
                label="Nome Completo"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                readOnly={Boolean(data)}
                required={!Boolean(data) ? "*Campo Obrigatório" : undefined}
                validation={validations.validateCPF}
                maskToSubmit={masks.removeNumberMask}
                mask={masks.CPF}
                defaultValue={data?.cpf || ""}
                name="cpf"
                label="CPF"
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                readOnly={Boolean(data)}
                required={!Boolean(data) ? "*Campo Obrigatório" : undefined}
                validation={validations.isEmailValid}
                defaultValue={data?.email || ""}
                name="email"
                label="E-Mail"
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InputTags
                white
                readOnly={view}
                required={"*Campo Obrigatório"}
                defaultValue={data?.places ? filteredPlaceList : null}
                name="studios"
                label="VINCULAR A STUDIOS"
                options={placeList ? placeList : []}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              paddingTop: "12px",
              width: "100%",
            }}
          >
            {!view && (
              <Styles.CancelButton
                fullWidth={false}
                onClick={() => {
                  customModal.close();
                }}
              >
                Cancelar
              </Styles.CancelButton>
            )}
            <Styles.RegitryButton
              fullWidth={false}
              disabled={loading}
              loading={loading}
              onClick={async () => {
                if(!loadingRef.current){
                  if (view) {
                    customModal.close();
                  } else {
                    loadingRef.current = true;
                    setLoading(true);
                    const submitInfo = await formRef?.testErrorsAndReturnData(
                      true
                    );

                    if (!submitInfo?.hasError) {
                      formRef?.submit();
                    }else{
                      loadingRef.current = false;
                      setLoading(false);
                    }
                  }
                }
              }}
            >
              {view
                ? "Voltar"
                : data
                ? "Confirmar Edição"
                : "Confirmar Cadastro"}
            </Styles.RegitryButton>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
