import React, { useContext } from "react";

import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table,
} from "../../components/index";
import Styles from "./styles";

import { masks } from "../../utils";
import { hooks } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";

import { api } from "../../services";

import { fonts, SVG } from "../../config";

import Visibility from "@material-ui/icons/Visibility";
import { Add, Check, Close, Edit, ExpandMore, Mail } from "@material-ui/icons";
import { customModal, errorModal } from "../../components/modals/utils";
import ModalRegistry from "./components/ModalRegistry";
import ModalView from "../myProperties/components/ModalView";
import alerts from "../../utils/alerts";
import { BsPersonFillSlash } from "react-icons/bs";
import ModalAprove from "./components/ModalAprove";

const PAGE_SIZE = 8;

function RegistryReservation() {
  const { loading, call } = hooks.useRequest();

  const [page, setPage] = React.useState<number>(0);

  const [reservation, setReservation] = React.useState<any>(null);
  const [placeList, setPlaceList] = React.useState<any>([]);

  const [isMounted, setMount] = React.useState<boolean>(false);

  const Mount = React.useCallback(() => {
    call(
      null,
      api.getReservation({
        page: 0,
        size: PAGE_SIZE,
      }),
      async (response) => {
        if (response.ok) {
          setReservation(response?.data);
        }
      }
    );
    call(
      null,
      api.getPlaces({
        page: 0,
        size: 999999,
      }),
      async (response) => {
        if (response.ok) {
          const list = response?.data?.content?.map((item) => {
            return { label: item.name, value: item.id };
          });
          setPlaceList(list);
        }
      }
    );
  }, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  const Submit = async (page?: number) => {
    let filters = {
      page: page ? page : 0,
      size: PAGE_SIZE,
    };

    call(null, api.getReservation(filters), async (response) => {
      if (response.ok) {
        setReservation(response?.data);
        setPage(page ? page : 0);
      }
    });
  };

  const openModal = () => {
    customModal.setInfos(
      "Cadastrar Reserva",
      [],
      null,
      null,
      <ModalRegistry
        page={page}
        placeList={placeList}
        reload={(value) => Submit(value)}
      />,
      false,
      true,
      <SVG.File />
    );
  };

  const openEditModal = (item) => {
    customModal.setInfos(
      "Editar Reserva",
      [],
      null,
      null,
      <ModalRegistry
        page={page}
        placeList={placeList}
        reload={(value) => Submit(value)}
        oldData={item}
        edit
      />,
      false,
      true,
      <Edit />
    );
  };

  const openAproveModal = (item) => {
    customModal.setInfos(
      "Aprovar Reserva",
      [],
      null,
      null,
      <ModalAprove page={page} item={item} reload={(value) => Submit(value)} />,
      false,
      true,
      <Check />
    );
  };

  const openModalView = (item) => {
    call(null, api.getReservationData(item.id), async (response) => {
      if (response.ok) {
        customModal.setInfos(
          "Visualizar Dados do Imóvel",
          [],
          {
            onClick: () => {
              customModal.close();
            },
            label: "Voltar",
          },
          null,
          <ModalView item={response?.data} />,
          false,
          true,
          <Visibility />
        );
      }
    });
  };

  const capitalizeText = (str) => {
    if (!str) {
      return str;
    }
    return str?.substring(0, 1)?.toUpperCase() + str?.substring(1);
  };

  const getStatus = (status: string) => {
    if (status === "FP") {
      return "Foto Pendente";
    } else if (status === "PA") {
      return "Aguardando Liberação";
    } else if (status === "AL") {
      return "Acesso Liberado";
    } else if (status === "AF") {
      return "Acesso Finalizado";
    } else if (status === "AR") {
      return "Acesso Recusado";
    } else if (status === "AC") {
      return "Acesso Cancelado";
    } else {
      return "-";
    }
  };

  const sendEmail = (item) => {
    customModal.setInfos(
      "Reenviar E-mail",
      [],
      null,
      null,
      <ModalAprove
        email
        page={page}
        item={item}
        reload={(value) => Submit(value)}
      />,
      false,
      false,
      <Mail />
    );
  };

  const deniedAccess = (id: string) => {
    call(null, api.patchDeniedAccess({ reservationId: id }), (response) => {
      if (response.ok) {
        Submit(page);
        customModal.setInfos(
          "Recusar Reserva",
          ["Reserva recusada com sucesso!"],
          {
            label: "Ok, Entendi",
            onClick: () => {
              customModal.close();
            },
          },
          null,
          null,
          false,
          true,
          <Close />
        );
      }
    });
  };

  const getButtons = (status: string, item: any): any[] => {
    if (status === "PA") {
      return [
        {
          label: "Editar Reserva",
          icon: Edit,
          onClick: () => {
            openEditModal(item);
          },
        },
        {
          label: "Aprovar Reserva",
          icon: Check,
          onClick: () => {
            openAproveModal(item);
          },
        },
        {
          label: "Recusar Reserva",
          icon: Close,
          onClick: () => {
            deniedAccess(item.id);
          },
        },
        {
          label: "Visualizar Dados",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
      ];
    } else if (status === "AL") {
      return [
        {
          label: "Editar Reserva",
          icon: Edit,
          onClick: () => {
            openEditModal(item);
          },
        },
        {
          label: "Visualizar Dados",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
        {
          label: "Reenviar E-mail",
          icon: Mail,
          onClick: () => {
            sendEmail(item);
          },
        },
        {
          label: "Revogar Acesso",
          icon: BsPersonFillSlash,
          onClick: () => {
            customModal.setInfos(
              "Revogar Acesso",
              [
                "Ao revogar o acesso deste imóvel, o usuário vinculado com o mesmo terá sua chave atual desativada, porém sua compra referente a este imóvel continuará ativa. Continuar e revogar acesso?",
              ],
              {
                onClick: () => {
                  revokeAccess(item.id);
                  customModal.close();
                },
                label: "Sim, Revogar",
              },
              {
                onClick: () => {
                  customModal.close();
                },
                label: "Voltar",
              },
              null,
              null,
              null,
              <BsPersonFillSlash />
            );
          },
        },
      ];
    } else if (status === "AF" || status == "FP") {
      return [
        {
          label: "Editar Reserva",
          icon: Edit,
          onClick: () => {
            openEditModal(item);
          },
        },
        {
          label: "Visualizar Dados",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
      ];
    } else if (status === "AR") {
      return [
        {
          label: "Visualizar Dados",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
      ];
    } else if (status === "AC") {
      return [
        {
          label: "Visualizar Dados",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
      ];
    } else {
      return [
        {
          label: "Visualizar Dados",
          icon: Visibility,
          onClick: () => {
            openModalView(item);
          },
        },
      ];
    }
  };

  const revokeAccess = (id: string) => {
    call(null, api.patchReservationRevoke(id), (response) => {
      if (response.ok) {
        Mount();
      }
    });
  };

  return (
    <>
      <Styles.ContainerSecondary>
        <Styles.RegitryContainer>
          <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
            Cadastrar Reserva
          </CustomText>
          <CustomText
            style={{ paddingRight: "8px" }}
            fontSize={1.75}
            textColor="#FFFFFF"
            fontFamily={fonts.regular}
          >
            Alguns dados básicos serão solicitados para o cadastro de uma nova
            reserva.
          </CustomText>
        </Styles.RegitryContainer>
        <Styles.RegitryButton
          startIcon={<Add />}
          fullWidth={false}
          onClick={openModal}
          disabled={loading}
          loading={loading}
        >
          Cadastrar
        </Styles.RegitryButton>
      </Styles.ContainerSecondary>
      <Styles.Container>
        <CustomText
          style={{ padding: "16px 20px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Histórico de Reservas Cadastradas Manualmente
        </CustomText>
        <ContentFeedback data={reservation?.content} loading={loading}>
          <Table
            lastAlign={false}
            data={reservation?.content}
            renderItemColumns={(item) => [
              capitalizeText(item?.primaryGuest?.name),
              item?.primaryGuest?.cpf ? item?.primaryGuest?.cpf : item?.primaryGuest?.passport,
              masks.formatDate(item?.startDate)+" "+masks.extractHour(item?.startDate),
              masks.formatDate(item?.endDate)+" "+masks.extractHour(item?.endDate),
              item?.primaryGuest?.email,
              item?.numGuests,

              masks?.formatDate(item?.startDate),
              <b>{getStatus(item?.status)}</b>,
              <ButtonMenu
                endIcon={<ExpandMore />}
                buttons={getButtons(item.status, item)}
              >
                Opções
              </ButtonMenu>,
            ]}
            headers={{
              table: [
                "NOME USUÁRIO",
                "DOCUMENTO",
                "CHECK-IN",
                "CHECK-OUT",
                "E-MAIL",
                "Nº ACOMPANHANTES",
                "DATA DO CADASTRO",
                "STATUS",
                "AÇÃO",
              ],
              keys: [],
            }}
          />
          <Pagination
            style={{ padding: "16px 20px", paddingTop: 0 }}
            page={page + 1}
            totalPages={reservation?.totalPages}
            setPage={(number) => {
              Submit(number - 1);
            }}
          />
        </ContentFeedback>
      </Styles.Container>
    </>
  );
}

export default RegistryReservation;
