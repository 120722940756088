import React from "react";
import { FormFull } from "form-full";
import Styles from "../styles/Styles";
import { Grid } from "@material-ui/core";
import { Input, InputAutocomplete } from "../../../components/index";

function Filters({ onSubmit, loading, placeList }) {
    return (
        <FormFull onSubmit={(data) => onSubmit(data)}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={3} lg={3}>
                    <Input name="fullName" label="NOME COMPLETO" placeholder="Nome Completo" />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Input name="phone" label="TELEFONE" placeholder="Telefone" />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Input name="email" label="E-mail" placeholder="E-mail" />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <InputAutocomplete
                        readOnly={false}
                        disableError
                        required={"*Campo Obrigatório"}
                        white
                        name="property"
                        label="Imóvel"
                        options={placeList ? placeList : []}
                    />
                </Grid>
            </Grid>
            <Styles.ButtonContainer>
                <Styles.Clear
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    action="clear"
                >
                    Limpar
                </Styles.Clear>
                <Styles.Submit
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    action="submit"
                >
                    Aplicar Filtros
                </Styles.Submit>
            </Styles.ButtonContainer>
        </FormFull>
    );
}

export default Filters;
