import React from "react";
import { FormFull } from "form-full";
import { masks } from "../../../utils";
import Styles from "../styles/Styles";
import { Grid } from "@material-ui/core";
import { Input } from "../../../components/index";

function Filters({ onSubmit, loading }) {
    return (
        <FormFull onSubmit={(data) => onSubmit(data)}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={8} lg={4}>
                    <Input name="name" label="Nome da Empresa" />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Input name="cnpj" label="CNPJ" mask={masks.CNPJ} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Input name="email" label="E-mail representante" />
                </Grid>
            </Grid>
            <Styles.ButtonContainer>
                <Styles.Clear
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    action="clear"
                >
                    Limpar
                </Styles.Clear>
                <Styles.Submit
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    action="submit"
                >
                    Aplicar Filtros
                </Styles.Submit>
            </Styles.ButtonContainer>
        </FormFull>
    );
}

export default Filters;
