import React, { useState } from "react";
import Styles from "./styles/Styles";
import { fonts, SVG } from "../../config";
import { Add, ExpandMore } from "@material-ui/icons";
import { customModal } from "../../components/modals/utils";
import ModalRegistry from "./components/ModalRegistry";
import { HiPencil } from "react-icons/hi2";
import {
    ButtonMenu,
    ContentFeedback,
    CustomText,
    Pagination,
    Table,
} from "../../components";
import { BsPersonFillSlash } from "react-icons/bs";
import Filters from "./components/Filters";

export default function RegisterTenant() {
    const [page, setPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = useState(0);

    const MOCK_DATA = [
        {
            id: 1,
            fullName: "Leandro Bittencourt",
            phone: "+5548991506666",
            email: "email01@exemplo.com.br",
            imovel: "Residencial dos Mock, Ap 404",
            entrada: "15/04/2024",
            saida: "15/05/2024",
        },
        {
            id: 2,
            fullName: "Taynara Soares",
            phone: "+5548991507777",
            email: "email01@exemplo.com.br",
            imovel: "Residencial dos Mock 2, Ap 404",
            entrada: "16/04/2024",
            saida: "16/05/2024",
        },
        {
            id: 3,
            fullName: "Leandro Bittencourt",
            phone: "+5548991508888",
            email: "email01@exemplo.com.br",
            imovel: "Residencial dos Mock 3-Vengence, Ap 404",
            entrada: "17/04/2024",
            saida: "17/05/2024",
        },
        {
            id: 4,
            fullName: "Taynara Soares",
            phone: "+5548991508889",
            email: "email01@exemplo.com.br",
            imovel: "Residencial dos Mock 4-Final, Ap 404",
            entrada: "18/04/2024",
            saida: "18/05/2024",
        },
        {
            id: 5,
            fullName: "Leandro Bittencourt",
            phone: "+5548991509999",
            email: "email01@exemplo.com.br",
            imovel: "Residencial dos Mock 5-Final part 2, Ap 404",
            entrada: "19/04/2024",
            saida: "19/05/2024",
        },
    ];

    const openRegisterModal = () => {
        customModal.setInfos(
            "Cadastrar Inquílino",
            [],
            null,
            null,
            <ModalRegistry placeList={[{ value: "mockValue", label: "mockLabel" }]} />,
            false,
            true,
            <SVG.Maintenance />
        );
    };

    const openEditModal = (data) => {
        customModal.setInfos(
            "Editar Dados do Inquílino",
            [],
            null,
            null,
            <ModalRegistry placeList={[{ value: "mockValue", label: "mockLabel" }]} data={data} onSubmit={data} />,
            false,
            true,
            <HiPencil />
        );
    };

    const RevokeModalAccess = (id: string) => {
        customModal.setInfos(
            "Revogar Acesso",
            [
                "Continuar e revogar acesso deste inquílino?",
            ],
            {
                label: "Sim, Revogar",
                onClick: () => {
                    customModal.close();
                },
            },
            {
                label: "Voltar",
                onClick: () => {
                    customModal.close();
                },
            },
            null,
            null,
            null,
            <BsPersonFillSlash />
        );
    };

    const getButtons = (item: any): any[] => {
        return [
            {
                label: "Editar Dados do Inquílino",
                icon: HiPencil,
                onClick: () => openEditModal(item),
            },
            {
                label: "Revogar Acesso",
                icon: BsPersonFillSlash,
                onClick: () => RevokeModalAccess(item.id),
            },
        ];
    };

    return (
        <>
            <Styles.PageNameContainer>
                <Styles.PageNameContent>
                    <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
                        Cadastrar Inquílino
                    </CustomText>
                    <CustomText
                        style={{ paddingRight: "8px" }}
                        fontSize={1.75}
                        textColor="#FFFFFF"
                        fontFamily={fonts.regular}
                    >
                        Alguns dados básicos serão solicitados para o cadastro de um novo
                        inquílino.
                    </CustomText>
                </Styles.PageNameContent>
                <Styles.RegitryButton
                    startIcon={<Add />}
                    fullWidth={false}
                    onClick={() => openRegisterModal()}
                >
                    Cadastrar
                </Styles.RegitryButton>
            </Styles.PageNameContainer>

            <Styles.ContainerFilter>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Filtrar Inquílinos Cadastrados
                </CustomText>
                <Filters onSubmit={(data) => console.log(data)} loading={false} placeList={[{ value: "mockValue", label: "mockLabel" }]} />
            </Styles.ContainerFilter>

            <Styles.Container>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Lista de Inquílinos Cadastrados
                </CustomText>
                <ContentFeedback data={MOCK_DATA} loading={false}>
                    <Table
                        lastAlign={false}
                        data={MOCK_DATA}
                        renderItemColumns={(item) => [
                            item.fullName,
                            item.phone,
                            item.email,
                            item.imovel,
                            item.entrada+" - "+item.saida,
                            null,
                            <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                                Opções
                            </ButtonMenu>,
                        ]}
                        headers={{
                            table: ["NOME COMPLETO", "TELEFONE", "E-MAIL", "IMÓVEL", "ESTADIA", "", "AÇÃO"],
                            keys: [],
                        }}
                    />
                    <Pagination
                        style={{ padding: "16px 20px", paddingTop: 0 }}
                        page={page}
                        totalPages={totalPages}
                        setPage={(number) => setPage(number)}
                    />
                </ContentFeedback>
            </Styles.Container>
        </>
    );
}
